import React from 'react';
import './About.css'; // Ensure the CSS file is linked

const About = () => {
  return (
    <div className="about-page">
      <section className="about-hero">
        <h1>About Us</h1>
        <p>Discover our journey, values, and the people behind Escudero Electrical Contractors LLC.</p>
      </section>
      <div className="content-with-image">
        <section className="about-content">
          <h2>Our Story</h2>
          <p>At Escudero Electrical Contractors LLC, our family-owned business brings over 30 years of experience to every electrical installation and repair. The expertise of our father-son team guarantees a range of professional services, from simple light bulb changes to complex 1200 AMP installations. We are committed to ensuring that your electrical needs are met with the highest standards of quality and the most effective solutions, ensuring complete satisfaction for every client we serve.</p>
        </section>
      </div>
      <div className="content-with-image">
        <section className="about-values">
          <h2>Our Mission</h2>
          <p>Our mission is to set the standard for excellence in electrical services. With meticulous attention to detail and a steadfast commitment to client satisfaction, we are committed to not only resolving your electrical issues but also ensuring you have a clear understanding of the solutions we provide. Trust in us to illuminate the path to secure and efficient electrical solutions, as we believe informed clients are empowered clients.</p>
        </section>
      </div>
      <div className="content-with-image">
        <section className="about-content">
          <h2>Safety</h2>
          <p>At the core of our service philosophy lies a commitment to safety and precision in every task we undertake, from comprehensive new construction electrical installations to the simplicity of a single receptacle addition. We diligently adhere to the highest standards of safety protocols to protect our valued electricians and, most importantly, to ensure the well-being of you and your residence. Each project is executed with an unwavering focus on delivering secure and reliable electrical solutions, affirming our dedication to your peace of mind.</p>
        </section>
      </div>
      {/* ... other sections ... */}
    </div>
  );
};

export default About;
