import React from 'react';
import './Gallery.css'; // Link to the CSS file for styling
import galleryData from '../static/js/gallery';

const Gallery = () => {
  return (
    <>
      <div className="gallery-page">
        <h1>Our Gallery</h1>
        <p>Explore our portfolio of projects to see the quality and craftsmanship we bring to every job.</p>
        <div className="gallery-grid">
          {galleryData.map((gallery) => (
            <div className="gallery-item">
              <img src={gallery.icon} alt={gallery.title} />
            </div>
          ))}
        </div>
      </div>
      {/* <Spacer height={30}/> */}
    </>
  );
};

export default Gallery;
