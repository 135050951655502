import React from 'react';
import './Contact.css';
import contactImage from '../static/assets/logo.png'; // Replace with your actual image path
import servicesData from '../static/js/services'; // Import the services data

const Contact = () => {

  const sortedServices = servicesData.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className="contact-page">
      <div className="contact-container">
        <div className="contact-image-container">
          <img src={contactImage} alt="Contact Us" className="contact-image" />
        </div>
        <div className="contact-form-container">
          <h1>Contact Us</h1>
          <p>Have questions? Our team is ready to help. 
            Reach out to us by filling the form below, email us
            at <a href="mailto:escuderoelectricalcontractors@gmail.com">escuderoelectricalcontractors@gmail.com</a>, 
            or call us at our English number at <a href="tel:+12244096234">(224) 409-6234</a> or 
            our Spanish number at <a href="tel:+17735108519">(773) 510-8519</a>.
            We'll get back to you as soon as possible.</p>
          <br></br>
          <form 
            name="contact-form"
            method="POST"
            data-netlify="true"
            onSubmit="submit"
            className="contact-form"
          >
            <input type="hidden" name="form-name" value="contact-form" />
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="phone">Phone</label>
            <input type="tel" id="phone" name="phone" required pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="Must be in the format: 123-456-7890"/>
            
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="service">Service</label>
            <select id="service" name="service" required>
              {sortedServices.map((service) => (
                <option key={service.id} value={service.title}>{service.title}</option>
              ))}
              <option value="other">Other</option>
            </select>
            
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="6" required></textarea>
            
            <button type="submit" className="contact-submit-button">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
