import React from 'react';
import './Services.css'; // Link to the CSS file for styling
import servicesData from '../static/js/services'; // Import the services data
// ... import other icons

const Services = () => {
  // return (
  //   <div className="services-page">
  //     <h1>Our Professional Services</h1>
  //     <p>At Escudero Electrical Contractors, we offer a wide range of electrical services to meet your needs. Our team of experienced professionals is here to provide top-notch service and expertise.</p>
  //     <div className="services-list">
  //       {servicesData.map((service) => (
  //         <div key={service.id} className="service-item">
  //           <img src={service.icon} alt={service.title} />
  //           <h2>{service.title}</h2>
  //           <p>{service.description}</p>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );

  return (
    <div className="services-page">
      <h1>Our Professional Services</h1>
      <p>At Escudero Electrical Contractors LLC, we offer a wide range of electrical services to meet your needs. Our team of experienced professionals is here to provide top-notch service and expertise.</p>
      <div className="services-list">
        {servicesData.map((service) => (
          <div key={service.id} className="service-item" style={{ backgroundImage: `url(${service.icon})` }}>
            <h2>{service.title}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
  


};

export default Services;
