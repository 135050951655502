import React from 'react';
import './CompanyInfo.css'; // Link to the CSS file for styling
import serviceIcon1 from '../static/assets/service_calls.jpg'; // Replace with your actual image path
import serviceIcon2 from '../static/assets/new_construction.png'; // Replace with your actual image path
import serviceIcon3 from '../static/assets/industrial.jpg'; // Replace with your actual image path

const CompInfo = () => {
  return (
    <div className="info-section">
      <h1>YOUR PREMIER SOURCE FOR COMPREHENSIVE ELECTRICAL SERVICES: FROM UPGRADES TO INSTALLATIONS AND BEYOND</h1>
      <p>At Escudero Electrical Contractors LLC, we are a locally-owned family business with over three decades of experience. Our dedication lies in ensuring every client's complete satisfaction. Regardless of the project size, we believe every task is significant. Rest assured, we're in your corner.</p>
      <div className="services-container">
        <div className="service">
          <img src={serviceIcon1} alt="" />
          <h2>Service Calls</h2>
          <p>Encountering an electrical issue can be a significant inconvenience. Rest assured, our expert team is ready to assist you. Regardless of the scale, from minor repairs to substantial projects, we approach every task with the same level of meticulous care and professionalism. Allow us to address your electrical concerns efficiently and effectively.</p>
        </div>
        <div className="service">
          <img src={serviceIcon2} alt="" />
          <h2>New Construction</h2>
          <p>Whether you're embarking on a renovation, remodeling venture, or undertaking a new construction project, Escudero Electrical Contractors LLC is equipped to meet all your electrical needs. We are committed to delivering top-tier service for projects of any size, ensuring meticulous attention to detail and unwavering diligence characteristic of our reputation. Entrust us to energize your space with expertise and precision.</p>
        </div>
        <div className="service">
          <img src={serviceIcon3} alt="" />
          <h2>Industrial</h2>
          <p>For industrial-grade electrical services, look no further than Escudero Electrical Contractors LLC. Our team is fully equipped to handle the complex demands of industrial settings. Contact us to discover how we can assist you with your specific requirements and ensure your project’s success with our expert solutions.</p>
        </div>
      </div>
      <a href='/about'>
        <button className="about-us-button">About Us</button>
      </a>
      
    </div>
  );
};

export default CompInfo;
