import React from 'react';
import './Footer.css'; // Make sure to create this CSS file
import logo from '../static/assets/logo.png'; // Adjust the path to your logo as necessary

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo-container-footer">
          <a href="/" className="logo-link">
            <img alt="Logo" src={logo} className="logo-img-footer" />
          </a>
        </div>
        {/* <div className="footer-block">
          <p>&copy; {new Date().getFullYear()} Escudero Electrical Contractors. All rights reserved.</p>
          <div className="footer-links">
            <a href="/about">About</a>
            <a href="/services">Services</a>
            <a href="/gallery">Gallery</a>
            <a href="/contact">Contact</a>
          </div>
        </div> */}
        <div className="map-container">
        <iframe
          title="Business Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190255.43573914774!2d-87.89676736101552!3d41.83387098121845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2c3cd0f4cbed%3A0xafe0a6ad09c0c000!2sChicago%2C%20IL!5e0!3m2!1sen!2sus!4v1707629667118!5m2!1sen!2sus" 
          style={{"border": 0}} 
          allowfullscreen=""
          >
        </iframe>
        </div>
        <div className="content-container">
          <div className="areas-served-container">
            <h3>Areas We Serve</h3>
            <ul className="areas-served-grid">
              <li>Naperville, IL</li>
              <li>Bolingbrook, IL</li>
              <li>Hinsdale, IL</li>
              <li>Palos Hills, IL</li>
              <li>Schaumburg, IL</li>
              <li>Plainfield, IL</li>
            </ul>
          </div>
        </div>
        
      </div>
      <div className="footer-block">
          <p>&copy; {new Date().getFullYear()} Escudero Electrical Contractors LLC. All rights reserved.</p>
          <div className="footer-links">
            <a href="/about">About</a>
            <a href="/services">Services</a>
            <a href="/gallery">Gallery</a>
            <a href="/contact">Contact</a>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
