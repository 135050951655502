import charging from '../assets/charging.png';
import service_calls from '../assets/service_calls.jpg';
import outlets from '../assets/outlets.jpg';
import remodel from '../assets/remodel.jpg';
import renovations from '../assets/renovations.jpg';
import newConstruction from '../assets/new_construction.png';
import electricalService from '../assets/electrical_service.png';
import lightFixtures from '../assets/light_fixtures.jpg';
import generatorInstalls from '../assets/generator_installs.jpg';

const servicesData = [
    {
      "id": 1,
      "icon": electricalService,
      "title": "Electrical Service Upgrading and Installation",
      "description": "We provide comprehensive electrical service upgrades and installations to ensure your home meets the latest safety standards."
    },
    {
      "id": 2,
      "icon": charging,
      "title": "Electric Charging Station Installation",
      "description": "Expert installation of electric vehicle charging stations, offering convenience and efficiency for your home."
    },
    {
      "id": 3,
      "icon": outlets,
      "title": "Installation of Outlets and Switches",
      "description": "Safe and reliable installation of outlets and switches to power your devices and lighting."
    },
    {
      "id": 4,
      "icon": lightFixtures,
      "title": "Installation of Fans and Light Fixtures",
      "description": "Enhance your home with our expert installation of fans and light fixtures, improving comfort and aesthetics."
    },
    {
      "id": 5,
      "icon": generatorInstalls,
      "title": "Generator Installs",
      "description": "Ensure uninterrupted power with our professional generator installation services."
    },
    {
      "id": 6,
      "icon": newConstruction,
      "title": "New Construction",
      "description": "Electrical planning and installation services tailored for new construction projects, from homes to commercial buildings."
    },
    {
      "id": 7,
      "icon": remodel,
      "title": "Remodels",
      "description": "Transform your space with our electrical remodeling services, designed to meet modern needs and aesthetics."
    },
    {
      "id": 8,
      "icon": renovations,
      "title": "Renovations",
      "description": "Upgrade your electrical systems with our renovation services, combining safety with innovative solutions."
    },
    {
      "id": 9,
      "icon": service_calls,
      "title": "Service Calls",
      "description": "Responsive and reliable electrical repair and maintenance services to address any issue promptly."
    }
  ]
  
export default servicesData;