import React from 'react';
import './Hero.css'; // Make sure to create this CSS file
// import heroImage from '../static/assets/hero_image.jpg'; // Replace with your hero image path
import heroImage from '../static/assets/new_background.jpg'; // Replace with your hero image path

const HeroSection = () => {
  return (
    <section className="hero">
      <img src={heroImage} alt="Hero" className="hero-image" />
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <div className="hero-text">
          <h1>Welcome to Escudero Electrical Contractors LLC</h1>
        </div>
        <a href='/contact'>
          <button className="quote-button">Get a Quote</button>
        </a>
      </div>
    </section>
  );
};

export default HeroSection;
