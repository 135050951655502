import React from 'react';
import HeroSection from '../components/hero';
import CompInfo from '../components/companyInfo';

const Home = () => {
  return (
    <>
        <HeroSection />
        <CompInfo />
    </>
    
  );
};

export default Home;